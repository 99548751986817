import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// assets
import { DragonIcon, LibraryIcon, AssignmentIcon } from "src/assets/icons";

// helpers
import { useScreenWidth } from "src/helpers";
import { signOutUser } from "src/services/localStorage";

// components
import SidebarLink from "./sidebarLink";
import ButtonToggle from "./buttonToggle";
import ButtonPlay from "./buttonPlay";
import Logo from "./logo";
import { DragonTimer, ButtonLogout } from "src/components";

// utils
import { getPendingAssignmentsCountActions } from "../../redux/actions/quiz";

// styles
import { StyledSidebar } from "./Sidebar.styles";

// types
import { RootReducer } from "../../redux/reducers";
import { AUTH_RESET_ACTION_TYPE } from "../../redux/reducers/auth";
import { setAnalyticsUserId } from "src/services/analytics/firebase";

const Sidebar = () => {
  const [screenWidth] = useScreenWidth();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(screenWidth <= 1024);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user: { userData },
    quiz: { pendingAssignmentsCount, studentAssignments }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const getPendingAssignments = useCallback(
    () =>
      dispatch(
        getPendingAssignmentsCountActions.request({
          userId: userData?.id,
          challengeTypeId: 9,
          excludeFuture: true,
          excludeCompleted: false
        })
      ),
    [dispatch]
  );

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logout = () => {
    dispatch({ type: AUTH_RESET_ACTION_TYPE });
    signOutUser();
    navigate("/login");
  };

  const isMobile = screenWidth <= 768;

  useEffect(() => {
    userData?.id && getPendingAssignments();
  }, [userData, studentAssignments]);

  useEffect(() => {
    userData?.id && setAnalyticsUserId(`${userData.id}`);
  }, [userData]);

  return (
    <StyledSidebar className={`${isCollapsed && !isMobile ? "collapsed" : ""}`}>
      {!isMobile && (
        <>
          <div className="logo-wrapper">
            <Logo isCollapsed={isCollapsed} />
          </div>
          {screenWidth <= 1024 && (
            <div className={`sidebar-toggle ${isCollapsed ? "rotate" : ""}`}>
              <ButtonToggle onClick={toggleSidebar} />
            </div>
          )}
        </>
      )}
      <div className="sidebar-nav">
        <SidebarLink
          url="/dragon"
          title={t("dragon")}
          icon={<DragonIcon width={isMobile ? 30 : 39} height={isMobile ? 25 : 32} />}
          isCollapsed={isCollapsed}
        />
        {isMobile && (
          <div className="sidebar-play">
            <ButtonPlay isCollapsed={isCollapsed} onClick={() => navigate("/growing-path")} />
          </div>
        )}
        <SidebarLink
          url="/library"
          title={t("Library")}
          icon={<LibraryIcon width={isMobile ? 21 : 31} height={isMobile ? 24 : 33} />}
          isCollapsed={isCollapsed}
        />
        <SidebarLink
          extraClassName="assignment-link"
          url="/assignments"
          title={t("menu_item_assignments")}
          icon={<AssignmentIcon width={isMobile ? 45 : 39} height={isMobile ? 26 : 38} />}
          isCollapsed={isCollapsed}
          badgeValue={pendingAssignmentsCount}
        />
        <SidebarLink
          url="/friends"
          title={t("Friends")}
          icon={<LibraryIcon width={isMobile ? 21 : 31} height={isMobile ? 24 : 33} />}
          isCollapsed={isCollapsed}
        />
      </div>
      {!isMobile && (
        <div className="sidebar-play">
          <ButtonPlay isCollapsed={isCollapsed} onClick={() => navigate("/growing-path")} />
        </div>
      )}
      <div className="sidebar-timer">
        <DragonTimer isCollapsed={isCollapsed} />
      </div>
      {!isMobile && (
        <div className="sidebar-logout">
          <ButtonLogout hideTitle={!isCollapsed} onClick={logout} />
        </div>
      )}
    </StyledSidebar>
  );
};

export default Sidebar;
